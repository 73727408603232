export const FAQData = [
  {
    question: "참가 확인서는 어떻게 발급받을 수 있나요?",
    answer: "없습니다.",
  },
  {
    question: "지원은 어떻게 하나요?",
    answer: "상단 배너 또는 홈의 APPLY 버튼을 누르시면 EVENTUS를 통해 참가 신청하실 수 있습니다.",
  },
  {
    question: "지원에 필요한 서류가 있나요?",
    answer: "따로 필요한 서류는 없지만 지원 시 문항들에 대해 최대한 성심 성의껏 작성해주시면 감사하겠습니다.",
  },
  {
    question: "지원서 및 설문에 작성한 개인정보는 어떻게 처리되나요?",
    answer: "동의서에 언급된 바와 같이, 행사기간에 참가 창업팀 및 후원사들에게 제공되며 행사 이후에는 모두안전하게 파기됩니다.",
  },
  {
    question: "기업에게 개인정보를 제공하고 싶지 않은 경우 참가할 수 없나요?",
    answer: "참가하실 수 있습니다. 다만, Matchmaking의 참여가 제한됩니다.",
  },
  {
    question: "참가 창업팀의 정보가 사전에 제공되나요?",
    answer: "네, 페이스북과 공식 홈페이지를 통해 참고하실 수 있는 기초자료가 제공될 예정입니다.",
  },
  {
    question: "미성년자도 Matchmaking & Party에 참석할 수 있나요?",
    answer: "Matchmaking & Party는 대전 시내의 클럽에서 진행되므로 미성년자는 참여할 수 없습니다. 양해 부탁 드립니다.",
  },
  {
    question: "대전에서 행사가 진행되던데, 교통편이 제공되나요?",
    answer: "서울->대전의 편도 버스가 준비되어 있습니다. 또한 대전 시내를 이동할 때, 주최측에서 대여한 버스를 이용하실 수 있습니다.",
  },
  {
    question: "GRAFFITI STARTUP FESTIVAL은 기존의 다른 스타트업 페스티벌과 무엇이 다른가요?",
    answer: "GRAFFTI STATRUP FESTIVAL은 올해 제 1회 행사로서 20대들의 축제의 장이 되고자 합니다! 제품이나 기술을 중요시하는 기존의 스타트업 페스티벌과는 달리 스토리와 멘탈리티가 메인이 되는 페스티벌입니다.",
  },
  {
    question: "GRAFFITI 참가비는 얼마인가요?",
    answer: "다양하고 많은 사람들이 창업에 도움을 받고 자유로운 창업 분위기 형성을 위해 참가비를 무료로 하고 있습니다. 클럽 파티를 포함한 모든 세션에 무료로 참여가 가능합니다.",
  },
  {
    question: "오후부터 밤 늦게까지 행사가 계속되던데, 숙식이 제공되나요?",
    answer: "토크 콘서트 이후 저녁식사를 참가자들에게 제공합니다. 또한, 2부의 클럽등의 장소에서 무한정으로 제공되는 주류와 간단한 다과를 즐기실 수 있습니다! 이때, 숙박은 제공되지 않습니다.",
  },
  {
    question: "참가 자격에 제한이 있나요?",
    answer: "창업에 흥미가 있는 누구나 신청 가능합니다. 현재 창업 생각이 없더라도 부담없이 행사를 즐기고 가시면 됩니다. 단, 미성년자의 경우 2부의 활동이 제한됩니다.",
  },
  {
    question: "이벤트 시간에는 어떠한 것들이 준비되어 있나요?",
    answer: "참가자들의 친목도모 및 활기찬 페스티벌을 위해 간단한 이벤트 및 상품들이 준비되어 있습니다. 직접 참여하여 확인하고 상품도 받아가세요!",
  },
  {
    question: "메치메이킹이 무엇인가요?",
    answer: "메치메이킹에서 참가자와 창업팀, 후원사 간의 1대1 미팅을 제공하고 있습니다. 미팅은 대전 시내의 카페에서 진행되며 이와 동시에 클럽에서는 애프터 파티가 진행됩니다.",
  },
  {
    question: "GRAFFITI STARTUP FESTIVAL은 누가 운영하나요?",
    answer: "GRAFFITI STARTUP FESTIVAL 조직위원회는 카이스트 학생단체ICISTS로서, 카이스트 학부생 47명으로 이루어져 있습니다. 행사 기획, 운영, 홍보, 재정 등 모든 업무를 카이스트 재학생들이 직접 진행하며 학부생만의 힘으로 행사를 운영하고 있습니다."
  }
];